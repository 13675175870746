import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAdministrativeMoreInformationMutationVariables = Types.Exact<{
  updateAdministrativeMoreInformationInput: Types.UpdateAdministrativeMoreInformationInput;
}>;


export type UpdateAdministrativeMoreInformationMutation = { __typename?: 'Mutation', updateAdministrativeMoreInformation: { __typename?: 'OrganizationObjectfiUALNUl', object?: { __typename?: 'OrganizationObject', id: string } | null } };

export type UpdateAdministrativeInformationMutationVariables = Types.Exact<{
  updateAdministrativeInformationInput: Types.UpdateAdministrativeInformationInput;
}>;


export type UpdateAdministrativeInformationMutation = { __typename?: 'Mutation', updateAdministrativeInformation: { __typename?: 'OrganizationObjectDiT8sSdG', object?: { __typename?: 'OrganizationObject', id: string, name: string, address?: string | null, city?: string | null, postalCode?: string | null, country: Types.OrganizationCountry, region?: string | null, charityNumber?: string | null, hasAdministrativeInformation: boolean, hasCharityNumber: boolean, canGenerateReceipt: boolean, referralQuestion?: Types.ReferralQuestionChoice | null, referralQuestionOther?: string | null, type?: Types.OrganizationType | null } | null } };

export type SetVolumeThresholdReachedMutationVariables = Types.Exact<{
  volumeThresholdReachedInput: Types.UpdateVolumeThresholdReached;
}>;


export type SetVolumeThresholdReachedMutation = { __typename?: 'Mutation', setVolumeThresholdReached: { __typename?: 'OrganizationResponseObject', object?: { __typename?: 'OrganizationObject', volumeThresholdReached?: number | null } | null } };

export type UpdatePayoutIntervalMutationVariables = Types.Exact<{
  updatePayoutInterval: Types.UpdatePayoutInterval;
}>;


export type UpdatePayoutIntervalMutation = { __typename?: 'Mutation', updatePayoutInterval: { __typename?: 'StripeAccountResponseObject', object?: { __typename?: 'StripeAccountObject', id: string } | null } };

export type CreateStripeCustomExternalAccountMutationVariables = Types.Exact<{
  externalAccountInput: Types.ExternalAccountInput;
}>;


export type CreateStripeCustomExternalAccountMutation = { __typename?: 'Mutation', createStripeCustomExternalAccount: { __typename?: 'ExternalAccountObjectXH3krwyV', object?: { __typename?: 'ExternalAccountObject', id: string } | null, error?: { __typename?: 'ExternalAccountObjectXH3krwyVErrorObject', code?: string | null } | null } };

export type GetReferredOrganizationQueryVariables = Types.Exact<{
  params: Types.GetReferredOrganizationInput;
}>;


export type GetReferredOrganizationQuery = { __typename?: 'Query', getReferredOrganization: { __typename?: 'OrganizationResponseObject', object?: { __typename?: 'OrganizationObject', id: string, name: string } | null } };

export type GetMyReferrerInformationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyReferrerInformationQuery = { __typename?: 'Query', getMyReferrerInformation: { __typename?: 'MyReferrerOrganizationResponseObject', object?: { __typename?: 'MyReferrerOrganizationObject', moneyGathered: number, organization: { __typename?: 'OrganizationObject', id: string, name: string } } | null } };

export type GetMyReferralOrganizationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyReferralOrganizationsQuery = { __typename?: 'Query', getMyReferralOrganizations: { __typename?: 'MyReferralOrganizationObjectxS7X3n9n', items?: Array<{ __typename?: 'MyReferralOrganizationObject', id: string, name: string, isStripeCustomAccountActive: boolean, count: number, email: string }> | null } };

export type UpdateCompanyOnboardingQualificationMutationVariables = Types.Exact<{
  qualificationInput: Types.OnboardingQualificationInput;
}>;


export type UpdateCompanyOnboardingQualificationMutation = { __typename?: 'Mutation', updateCompanyOnboardingQualification: { __typename?: 'BooleanResponseObject', object?: boolean | null } };

export type GetCurrentlyActiveOrganizationsQueryVariables = Types.Exact<{
  input: Types.Scalars['String'];
}>;


export type GetCurrentlyActiveOrganizationsQuery = { __typename?: 'Query', getCurrentlyActiveOrganizations: { __typename?: 'CurrentlyActiveOrganizationResponseListObject', items?: Array<{ __typename?: 'CurrentlyActiveOrganizationObject', id: string, name: string }> | null, error?: { __typename?: 'OrganizationResponseListObjectErrorObject', code?: string | null, message: string } | null } };

export type DeactivateOrganizationMutationVariables = Types.Exact<{
  input: Types.DeactivateAccountInput;
}>;


export type DeactivateOrganizationMutation = { __typename?: 'Mutation', deactivateOrganization: { __typename?: 'BooleanResponseObject', object?: boolean | null } };

export type CreateNewOrganizationMutationVariables = Types.Exact<{
  newOrganizationInput: Types.CreateNewOrganizationInput;
}>;


export type CreateNewOrganizationMutation = { __typename?: 'Mutation', createNewOrganization: { __typename?: 'AccessTokenObjectjtf41tI5', object?: { __typename?: 'AccessTokenObject', id?: string | null, role?: Types.UserRoles | null, locale?: Types.Locales | null } | null, error?: { __typename?: 'AccessTokenObjectjtf41tI5ErrorObject', code?: string | null } | null } };

export type GetCardVolumeQueryVariables = Types.Exact<{
  parameters?: Types.InputMaybe<Types.HomeMetricsInput>;
}>;


export type GetCardVolumeQuery = { __typename?: 'Query', getCardVolume: { __typename?: 'CardVolumeResponseObject', object?: { __typename?: 'CardVolumeObject', amount: number, count: number } | null } };

export type GetVolumeAtDateQueryVariables = Types.Exact<{
  startDate: Types.Scalars['Date'];
  endDate: Types.Scalars['Date'];
}>;


export type GetVolumeAtDateQuery = { __typename?: 'Query', getVolumeAtDate: { __typename?: 'NumberResponseObject', object?: number | null } };

export type GetStripeAccountBalanceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStripeAccountBalanceQuery = { __typename?: 'Query', getStripeAccountBalance: { __typename?: 'AccountBalancesObjectDvTJYDHq', object?: { __typename?: 'AccountBalancesObject', available?: Array<{ __typename?: 'BalanceObject', amount: number, currency: string }> | null, pending?: Array<{ __typename?: 'BalanceObject', amount: number, currency: string }> | null, issuing?: { __typename?: 'AccountBalancesObject', available?: Array<{ __typename?: 'BalanceObject', amount: number, currency: string }> | null, pending?: Array<{ __typename?: 'BalanceObject', amount: number, currency: string }> | null } | null } | null } };

export type UpdateOrganizationWithStripeCustomFlowHasStartedMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateOrganizationWithStripeCustomFlowHasStartedMutation = { __typename?: 'Mutation', updateOrganizationWithStripeCustomFlowHasStarted: { __typename?: 'BooleanjkgEsftj', object?: boolean | null } };

export type GetStripeAccountLinkQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStripeAccountLinkQuery = { __typename?: 'Query', getStripeAccountLink: { __typename?: 'StringW48tpVcB', object?: string | null, error?: { __typename?: 'StringW48tpVcBErrorObject', code?: string | null, message: string } | null } };

export type GetOrganizationListByInputQueryVariables = Types.Exact<{
  searchOragnizationInput: Types.Scalars['String'];
}>;


export type GetOrganizationListByInputQuery = { __typename?: 'Query', getOrganizationListByInput: { __typename?: 'OrganizationAndOwnerObjectdQ05oeuM', items?: Array<{ __typename?: 'OrganizationAndOwnerObject', name: string, email: string, userId: string, organizationId: string, role: string }> | null } };

export type RetrieveOrganizationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RetrieveOrganizationQuery = { __typename?: 'Query', retrieveOrganization: { __typename?: 'OrganizationPublicResponseObject', object?: { __typename?: 'OrganizationPublicObject', id: string, name: string, country: Types.OrganizationCountry, logoUrl?: string | null, phoneNumber?: string | null, owner?: { __typename?: 'OwnerObject', id: string, email?: string | null, phone?: string | null } | null } | null } };

export type UpdateOrganizationHubspotPropertyMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationHubspotPropertyInput;
}>;


export type UpdateOrganizationHubspotPropertyMutation = { __typename?: 'Mutation', updateOrganizationHubspotProperty: { __typename?: 'BooleanResponseObject', object?: boolean | null } };

export type UpdateOrganizationCategoryMutationVariables = Types.Exact<{
  category: Types.OrganizationCategory;
}>;


export type UpdateOrganizationCategoryMutation = { __typename?: 'Mutation', updateOrganizationCategory: { __typename?: 'BooleanResponseObject', object?: boolean | null } };

export type UpdateOrganizationOnboardedFormCategoryMutationVariables = Types.Exact<{
  onboardedFormCategory: Types.FormCategory;
}>;


export type UpdateOrganizationOnboardedFormCategoryMutation = { __typename?: 'Mutation', updateOrganizationOnboardedFormCategory: { __typename?: 'Booleanl7LOpgj1', object?: boolean | null } };

export type DismissActivationPayoutSuccessCardMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DismissActivationPayoutSuccessCardMutation = { __typename?: 'Mutation', dismissActivationPayoutSuccessCard: { __typename?: 'BooleanShZ36B0d', object?: boolean | null } };

export type AddEngagementStepCheckedMutationVariables = Types.Exact<{
  activationStepId: Types.Scalars['String'];
}>;


export type AddEngagementStepCheckedMutation = { __typename?: 'Mutation', addEngagementStepChecked: { __typename?: 'BooleanidVg57Hq', object?: boolean | null } };

export type ResetEngagementStepCheckedMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ResetEngagementStepCheckedMutation = { __typename?: 'Mutation', resetEngagementStepChecked: { __typename?: 'Booleanz7QfryU2', object?: boolean | null } };

export type DisplayEngagementChecklistMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DisplayEngagementChecklistMutation = { __typename?: 'Mutation', displayEngagementChecklist: { __typename?: 'BooleanipB8jsGN', object?: boolean | null } };

export type RemoveEngagementChecklistMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveEngagementChecklistMutation = { __typename?: 'Mutation', removeEngagementChecklist: { __typename?: 'BooleanrmtLpMUE', object?: boolean | null } };

export type UpdateOrganizationFlagsMutationVariables = Types.Exact<{
  input: Types.OrganizationFlagsInput;
}>;


export type UpdateOrganizationFlagsMutation = { __typename?: 'Mutation', updateOrganizationFlags: { __typename?: 'BooleanzBW326fj', object?: boolean | null, error?: { __typename?: 'BooleanzBW326fjErrorObject', code?: string | null, message: string } | null } };


export const UpdateAdministrativeMoreInformationDocument = gql`
    mutation updateAdministrativeMoreInformation($updateAdministrativeMoreInformationInput: UpdateAdministrativeMoreInformationInput!) {
  updateAdministrativeMoreInformation(
    updateAdministrativeMoreInformationInput: $updateAdministrativeMoreInformationInput
  ) {
    object {
      id
    }
  }
}
    `;
export type UpdateAdministrativeMoreInformationMutationFn = Apollo.MutationFunction<UpdateAdministrativeMoreInformationMutation, UpdateAdministrativeMoreInformationMutationVariables>;

/**
 * __useUpdateAdministrativeMoreInformationMutation__
 *
 * To run a mutation, you first call `useUpdateAdministrativeMoreInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdministrativeMoreInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdministrativeMoreInformationMutation, { data, loading, error }] = useUpdateAdministrativeMoreInformationMutation({
 *   variables: {
 *      updateAdministrativeMoreInformationInput: // value for 'updateAdministrativeMoreInformationInput'
 *   },
 * });
 */
export function useUpdateAdministrativeMoreInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdministrativeMoreInformationMutation, UpdateAdministrativeMoreInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdministrativeMoreInformationMutation, UpdateAdministrativeMoreInformationMutationVariables>(UpdateAdministrativeMoreInformationDocument, options);
      }
export type UpdateAdministrativeMoreInformationMutationHookResult = ReturnType<typeof useUpdateAdministrativeMoreInformationMutation>;
export type UpdateAdministrativeMoreInformationMutationResult = Apollo.MutationResult<UpdateAdministrativeMoreInformationMutation>;
export type UpdateAdministrativeMoreInformationMutationOptions = Apollo.BaseMutationOptions<UpdateAdministrativeMoreInformationMutation, UpdateAdministrativeMoreInformationMutationVariables>;
export const UpdateAdministrativeInformationDocument = gql`
    mutation updateAdministrativeInformation($updateAdministrativeInformationInput: UpdateAdministrativeInformationInput!) {
  updateAdministrativeInformation(
    updateAdministrativeInformationInput: $updateAdministrativeInformationInput
  ) {
    object {
      id
      name
      address
      city
      postalCode
      country
      region
      charityNumber
      hasAdministrativeInformation
      hasCharityNumber
      canGenerateReceipt
      referralQuestion
      referralQuestionOther
      type
      hasAdministrativeInformation
    }
  }
}
    `;
export type UpdateAdministrativeInformationMutationFn = Apollo.MutationFunction<UpdateAdministrativeInformationMutation, UpdateAdministrativeInformationMutationVariables>;

/**
 * __useUpdateAdministrativeInformationMutation__
 *
 * To run a mutation, you first call `useUpdateAdministrativeInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdministrativeInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdministrativeInformationMutation, { data, loading, error }] = useUpdateAdministrativeInformationMutation({
 *   variables: {
 *      updateAdministrativeInformationInput: // value for 'updateAdministrativeInformationInput'
 *   },
 * });
 */
export function useUpdateAdministrativeInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdministrativeInformationMutation, UpdateAdministrativeInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdministrativeInformationMutation, UpdateAdministrativeInformationMutationVariables>(UpdateAdministrativeInformationDocument, options);
      }
export type UpdateAdministrativeInformationMutationHookResult = ReturnType<typeof useUpdateAdministrativeInformationMutation>;
export type UpdateAdministrativeInformationMutationResult = Apollo.MutationResult<UpdateAdministrativeInformationMutation>;
export type UpdateAdministrativeInformationMutationOptions = Apollo.BaseMutationOptions<UpdateAdministrativeInformationMutation, UpdateAdministrativeInformationMutationVariables>;
export const SetVolumeThresholdReachedDocument = gql`
    mutation setVolumeThresholdReached($volumeThresholdReachedInput: UpdateVolumeThresholdReached!) {
  setVolumeThresholdReached(
    volumeThresholdReachedInput: $volumeThresholdReachedInput
  ) {
    object {
      volumeThresholdReached
    }
  }
}
    `;
export type SetVolumeThresholdReachedMutationFn = Apollo.MutationFunction<SetVolumeThresholdReachedMutation, SetVolumeThresholdReachedMutationVariables>;

/**
 * __useSetVolumeThresholdReachedMutation__
 *
 * To run a mutation, you first call `useSetVolumeThresholdReachedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVolumeThresholdReachedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVolumeThresholdReachedMutation, { data, loading, error }] = useSetVolumeThresholdReachedMutation({
 *   variables: {
 *      volumeThresholdReachedInput: // value for 'volumeThresholdReachedInput'
 *   },
 * });
 */
export function useSetVolumeThresholdReachedMutation(baseOptions?: Apollo.MutationHookOptions<SetVolumeThresholdReachedMutation, SetVolumeThresholdReachedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetVolumeThresholdReachedMutation, SetVolumeThresholdReachedMutationVariables>(SetVolumeThresholdReachedDocument, options);
      }
export type SetVolumeThresholdReachedMutationHookResult = ReturnType<typeof useSetVolumeThresholdReachedMutation>;
export type SetVolumeThresholdReachedMutationResult = Apollo.MutationResult<SetVolumeThresholdReachedMutation>;
export type SetVolumeThresholdReachedMutationOptions = Apollo.BaseMutationOptions<SetVolumeThresholdReachedMutation, SetVolumeThresholdReachedMutationVariables>;
export const UpdatePayoutIntervalDocument = gql`
    mutation updatePayoutInterval($updatePayoutInterval: UpdatePayoutInterval!) {
  updatePayoutInterval(updatePayoutInterval: $updatePayoutInterval) {
    object {
      id
    }
  }
}
    `;
export type UpdatePayoutIntervalMutationFn = Apollo.MutationFunction<UpdatePayoutIntervalMutation, UpdatePayoutIntervalMutationVariables>;

/**
 * __useUpdatePayoutIntervalMutation__
 *
 * To run a mutation, you first call `useUpdatePayoutIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayoutIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayoutIntervalMutation, { data, loading, error }] = useUpdatePayoutIntervalMutation({
 *   variables: {
 *      updatePayoutInterval: // value for 'updatePayoutInterval'
 *   },
 * });
 */
export function useUpdatePayoutIntervalMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayoutIntervalMutation, UpdatePayoutIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayoutIntervalMutation, UpdatePayoutIntervalMutationVariables>(UpdatePayoutIntervalDocument, options);
      }
export type UpdatePayoutIntervalMutationHookResult = ReturnType<typeof useUpdatePayoutIntervalMutation>;
export type UpdatePayoutIntervalMutationResult = Apollo.MutationResult<UpdatePayoutIntervalMutation>;
export type UpdatePayoutIntervalMutationOptions = Apollo.BaseMutationOptions<UpdatePayoutIntervalMutation, UpdatePayoutIntervalMutationVariables>;
export const CreateStripeCustomExternalAccountDocument = gql`
    mutation createStripeCustomExternalAccount($externalAccountInput: ExternalAccountInput!) {
  createStripeCustomExternalAccount(externalAccountInput: $externalAccountInput) {
    object {
      id
    }
    error {
      code
    }
  }
}
    `;
export type CreateStripeCustomExternalAccountMutationFn = Apollo.MutationFunction<CreateStripeCustomExternalAccountMutation, CreateStripeCustomExternalAccountMutationVariables>;

/**
 * __useCreateStripeCustomExternalAccountMutation__
 *
 * To run a mutation, you first call `useCreateStripeCustomExternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCustomExternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCustomExternalAccountMutation, { data, loading, error }] = useCreateStripeCustomExternalAccountMutation({
 *   variables: {
 *      externalAccountInput: // value for 'externalAccountInput'
 *   },
 * });
 */
export function useCreateStripeCustomExternalAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCustomExternalAccountMutation, CreateStripeCustomExternalAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCustomExternalAccountMutation, CreateStripeCustomExternalAccountMutationVariables>(CreateStripeCustomExternalAccountDocument, options);
      }
export type CreateStripeCustomExternalAccountMutationHookResult = ReturnType<typeof useCreateStripeCustomExternalAccountMutation>;
export type CreateStripeCustomExternalAccountMutationResult = Apollo.MutationResult<CreateStripeCustomExternalAccountMutation>;
export type CreateStripeCustomExternalAccountMutationOptions = Apollo.BaseMutationOptions<CreateStripeCustomExternalAccountMutation, CreateStripeCustomExternalAccountMutationVariables>;
export const GetReferredOrganizationDocument = gql`
    query getReferredOrganization($params: GetReferredOrganizationInput!) {
  getReferredOrganization(params: $params) {
    object {
      id
      name
    }
  }
}
    `;

/**
 * __useGetReferredOrganizationQuery__
 *
 * To run a query within a React component, call `useGetReferredOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferredOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferredOrganizationQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetReferredOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetReferredOrganizationQuery, GetReferredOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferredOrganizationQuery, GetReferredOrganizationQueryVariables>(GetReferredOrganizationDocument, options);
      }
export function useGetReferredOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferredOrganizationQuery, GetReferredOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferredOrganizationQuery, GetReferredOrganizationQueryVariables>(GetReferredOrganizationDocument, options);
        }
export type GetReferredOrganizationQueryHookResult = ReturnType<typeof useGetReferredOrganizationQuery>;
export type GetReferredOrganizationLazyQueryHookResult = ReturnType<typeof useGetReferredOrganizationLazyQuery>;
export type GetReferredOrganizationQueryResult = Apollo.QueryResult<GetReferredOrganizationQuery, GetReferredOrganizationQueryVariables>;
export const GetMyReferrerInformationDocument = gql`
    query getMyReferrerInformation {
  getMyReferrerInformation {
    object {
      organization {
        id
        name
      }
      moneyGathered
    }
  }
}
    `;

/**
 * __useGetMyReferrerInformationQuery__
 *
 * To run a query within a React component, call `useGetMyReferrerInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyReferrerInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyReferrerInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyReferrerInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetMyReferrerInformationQuery, GetMyReferrerInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyReferrerInformationQuery, GetMyReferrerInformationQueryVariables>(GetMyReferrerInformationDocument, options);
      }
export function useGetMyReferrerInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyReferrerInformationQuery, GetMyReferrerInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyReferrerInformationQuery, GetMyReferrerInformationQueryVariables>(GetMyReferrerInformationDocument, options);
        }
export type GetMyReferrerInformationQueryHookResult = ReturnType<typeof useGetMyReferrerInformationQuery>;
export type GetMyReferrerInformationLazyQueryHookResult = ReturnType<typeof useGetMyReferrerInformationLazyQuery>;
export type GetMyReferrerInformationQueryResult = Apollo.QueryResult<GetMyReferrerInformationQuery, GetMyReferrerInformationQueryVariables>;
export const GetMyReferralOrganizationsDocument = gql`
    query getMyReferralOrganizations {
  getMyReferralOrganizations {
    items {
      id
      name
      isStripeCustomAccountActive
      count
      email
    }
  }
}
    `;

/**
 * __useGetMyReferralOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetMyReferralOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyReferralOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyReferralOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyReferralOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyReferralOrganizationsQuery, GetMyReferralOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyReferralOrganizationsQuery, GetMyReferralOrganizationsQueryVariables>(GetMyReferralOrganizationsDocument, options);
      }
export function useGetMyReferralOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyReferralOrganizationsQuery, GetMyReferralOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyReferralOrganizationsQuery, GetMyReferralOrganizationsQueryVariables>(GetMyReferralOrganizationsDocument, options);
        }
export type GetMyReferralOrganizationsQueryHookResult = ReturnType<typeof useGetMyReferralOrganizationsQuery>;
export type GetMyReferralOrganizationsLazyQueryHookResult = ReturnType<typeof useGetMyReferralOrganizationsLazyQuery>;
export type GetMyReferralOrganizationsQueryResult = Apollo.QueryResult<GetMyReferralOrganizationsQuery, GetMyReferralOrganizationsQueryVariables>;
export const UpdateCompanyOnboardingQualificationDocument = gql`
    mutation updateCompanyOnboardingQualification($qualificationInput: OnboardingQualificationInput!) {
  updateCompanyOnboardingQualification(qualificationInput: $qualificationInput) {
    object
  }
}
    `;
export type UpdateCompanyOnboardingQualificationMutationFn = Apollo.MutationFunction<UpdateCompanyOnboardingQualificationMutation, UpdateCompanyOnboardingQualificationMutationVariables>;

/**
 * __useUpdateCompanyOnboardingQualificationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyOnboardingQualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyOnboardingQualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyOnboardingQualificationMutation, { data, loading, error }] = useUpdateCompanyOnboardingQualificationMutation({
 *   variables: {
 *      qualificationInput: // value for 'qualificationInput'
 *   },
 * });
 */
export function useUpdateCompanyOnboardingQualificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyOnboardingQualificationMutation, UpdateCompanyOnboardingQualificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyOnboardingQualificationMutation, UpdateCompanyOnboardingQualificationMutationVariables>(UpdateCompanyOnboardingQualificationDocument, options);
      }
export type UpdateCompanyOnboardingQualificationMutationHookResult = ReturnType<typeof useUpdateCompanyOnboardingQualificationMutation>;
export type UpdateCompanyOnboardingQualificationMutationResult = Apollo.MutationResult<UpdateCompanyOnboardingQualificationMutation>;
export type UpdateCompanyOnboardingQualificationMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyOnboardingQualificationMutation, UpdateCompanyOnboardingQualificationMutationVariables>;
export const GetCurrentlyActiveOrganizationsDocument = gql`
    query getCurrentlyActiveOrganizations($input: String!) {
  getCurrentlyActiveOrganizations(input: $input) {
    items {
      id
      name
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetCurrentlyActiveOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetCurrentlyActiveOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentlyActiveOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentlyActiveOrganizationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCurrentlyActiveOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentlyActiveOrganizationsQuery, GetCurrentlyActiveOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentlyActiveOrganizationsQuery, GetCurrentlyActiveOrganizationsQueryVariables>(GetCurrentlyActiveOrganizationsDocument, options);
      }
export function useGetCurrentlyActiveOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentlyActiveOrganizationsQuery, GetCurrentlyActiveOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentlyActiveOrganizationsQuery, GetCurrentlyActiveOrganizationsQueryVariables>(GetCurrentlyActiveOrganizationsDocument, options);
        }
export type GetCurrentlyActiveOrganizationsQueryHookResult = ReturnType<typeof useGetCurrentlyActiveOrganizationsQuery>;
export type GetCurrentlyActiveOrganizationsLazyQueryHookResult = ReturnType<typeof useGetCurrentlyActiveOrganizationsLazyQuery>;
export type GetCurrentlyActiveOrganizationsQueryResult = Apollo.QueryResult<GetCurrentlyActiveOrganizationsQuery, GetCurrentlyActiveOrganizationsQueryVariables>;
export const DeactivateOrganizationDocument = gql`
    mutation deactivateOrganization($input: DeactivateAccountInput!) {
  deactivateOrganization(input: $input) {
    object
  }
}
    `;
export type DeactivateOrganizationMutationFn = Apollo.MutationFunction<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>;

/**
 * __useDeactivateOrganizationMutation__
 *
 * To run a mutation, you first call `useDeactivateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateOrganizationMutation, { data, loading, error }] = useDeactivateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>(DeactivateOrganizationDocument, options);
      }
export type DeactivateOrganizationMutationHookResult = ReturnType<typeof useDeactivateOrganizationMutation>;
export type DeactivateOrganizationMutationResult = Apollo.MutationResult<DeactivateOrganizationMutation>;
export type DeactivateOrganizationMutationOptions = Apollo.BaseMutationOptions<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>;
export const CreateNewOrganizationDocument = gql`
    mutation createNewOrganization($newOrganizationInput: CreateNewOrganizationInput!) {
  createNewOrganization(newOrganizationInput: $newOrganizationInput) {
    object {
      id
      role
      locale
    }
    error {
      code
    }
  }
}
    `;
export type CreateNewOrganizationMutationFn = Apollo.MutationFunction<CreateNewOrganizationMutation, CreateNewOrganizationMutationVariables>;

/**
 * __useCreateNewOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateNewOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewOrganizationMutation, { data, loading, error }] = useCreateNewOrganizationMutation({
 *   variables: {
 *      newOrganizationInput: // value for 'newOrganizationInput'
 *   },
 * });
 */
export function useCreateNewOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewOrganizationMutation, CreateNewOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewOrganizationMutation, CreateNewOrganizationMutationVariables>(CreateNewOrganizationDocument, options);
      }
export type CreateNewOrganizationMutationHookResult = ReturnType<typeof useCreateNewOrganizationMutation>;
export type CreateNewOrganizationMutationResult = Apollo.MutationResult<CreateNewOrganizationMutation>;
export type CreateNewOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateNewOrganizationMutation, CreateNewOrganizationMutationVariables>;
export const GetCardVolumeDocument = gql`
    query getCardVolume($parameters: HomeMetricsInput) {
  getCardVolume(parameters: $parameters) {
    object {
      amount
      count
    }
  }
}
    `;

/**
 * __useGetCardVolumeQuery__
 *
 * To run a query within a React component, call `useGetCardVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardVolumeQuery({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useGetCardVolumeQuery(baseOptions?: Apollo.QueryHookOptions<GetCardVolumeQuery, GetCardVolumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardVolumeQuery, GetCardVolumeQueryVariables>(GetCardVolumeDocument, options);
      }
export function useGetCardVolumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardVolumeQuery, GetCardVolumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardVolumeQuery, GetCardVolumeQueryVariables>(GetCardVolumeDocument, options);
        }
export type GetCardVolumeQueryHookResult = ReturnType<typeof useGetCardVolumeQuery>;
export type GetCardVolumeLazyQueryHookResult = ReturnType<typeof useGetCardVolumeLazyQuery>;
export type GetCardVolumeQueryResult = Apollo.QueryResult<GetCardVolumeQuery, GetCardVolumeQueryVariables>;
export const GetVolumeAtDateDocument = gql`
    query getVolumeAtDate($startDate: Date!, $endDate: Date!) {
  getVolumeAtDate(startDate: $startDate, endDate: $endDate) {
    object
  }
}
    `;

/**
 * __useGetVolumeAtDateQuery__
 *
 * To run a query within a React component, call `useGetVolumeAtDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVolumeAtDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVolumeAtDateQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetVolumeAtDateQuery(baseOptions: Apollo.QueryHookOptions<GetVolumeAtDateQuery, GetVolumeAtDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVolumeAtDateQuery, GetVolumeAtDateQueryVariables>(GetVolumeAtDateDocument, options);
      }
export function useGetVolumeAtDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVolumeAtDateQuery, GetVolumeAtDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVolumeAtDateQuery, GetVolumeAtDateQueryVariables>(GetVolumeAtDateDocument, options);
        }
export type GetVolumeAtDateQueryHookResult = ReturnType<typeof useGetVolumeAtDateQuery>;
export type GetVolumeAtDateLazyQueryHookResult = ReturnType<typeof useGetVolumeAtDateLazyQuery>;
export type GetVolumeAtDateQueryResult = Apollo.QueryResult<GetVolumeAtDateQuery, GetVolumeAtDateQueryVariables>;
export const GetStripeAccountBalanceDocument = gql`
    query getStripeAccountBalance {
  getStripeAccountBalance {
    object {
      available {
        amount
        currency
      }
      pending {
        amount
        currency
      }
      issuing {
        available {
          amount
          currency
        }
        pending {
          amount
          currency
        }
      }
    }
  }
}
    `;

/**
 * __useGetStripeAccountBalanceQuery__
 *
 * To run a query within a React component, call `useGetStripeAccountBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeAccountBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeAccountBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeAccountBalanceQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>(GetStripeAccountBalanceDocument, options);
      }
export function useGetStripeAccountBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>(GetStripeAccountBalanceDocument, options);
        }
export type GetStripeAccountBalanceQueryHookResult = ReturnType<typeof useGetStripeAccountBalanceQuery>;
export type GetStripeAccountBalanceLazyQueryHookResult = ReturnType<typeof useGetStripeAccountBalanceLazyQuery>;
export type GetStripeAccountBalanceQueryResult = Apollo.QueryResult<GetStripeAccountBalanceQuery, GetStripeAccountBalanceQueryVariables>;
export const UpdateOrganizationWithStripeCustomFlowHasStartedDocument = gql`
    mutation updateOrganizationWithStripeCustomFlowHasStarted {
  updateOrganizationWithStripeCustomFlowHasStarted {
    object
  }
}
    `;
export type UpdateOrganizationWithStripeCustomFlowHasStartedMutationFn = Apollo.MutationFunction<UpdateOrganizationWithStripeCustomFlowHasStartedMutation, UpdateOrganizationWithStripeCustomFlowHasStartedMutationVariables>;

/**
 * __useUpdateOrganizationWithStripeCustomFlowHasStartedMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationWithStripeCustomFlowHasStartedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationWithStripeCustomFlowHasStartedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationWithStripeCustomFlowHasStartedMutation, { data, loading, error }] = useUpdateOrganizationWithStripeCustomFlowHasStartedMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateOrganizationWithStripeCustomFlowHasStartedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationWithStripeCustomFlowHasStartedMutation, UpdateOrganizationWithStripeCustomFlowHasStartedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationWithStripeCustomFlowHasStartedMutation, UpdateOrganizationWithStripeCustomFlowHasStartedMutationVariables>(UpdateOrganizationWithStripeCustomFlowHasStartedDocument, options);
      }
export type UpdateOrganizationWithStripeCustomFlowHasStartedMutationHookResult = ReturnType<typeof useUpdateOrganizationWithStripeCustomFlowHasStartedMutation>;
export type UpdateOrganizationWithStripeCustomFlowHasStartedMutationResult = Apollo.MutationResult<UpdateOrganizationWithStripeCustomFlowHasStartedMutation>;
export type UpdateOrganizationWithStripeCustomFlowHasStartedMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationWithStripeCustomFlowHasStartedMutation, UpdateOrganizationWithStripeCustomFlowHasStartedMutationVariables>;
export const GetStripeAccountLinkDocument = gql`
    query getStripeAccountLink {
  getStripeAccountLink {
    object
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetStripeAccountLinkQuery__
 *
 * To run a query within a React component, call `useGetStripeAccountLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeAccountLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeAccountLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeAccountLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>(GetStripeAccountLinkDocument, options);
      }
export function useGetStripeAccountLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>(GetStripeAccountLinkDocument, options);
        }
export type GetStripeAccountLinkQueryHookResult = ReturnType<typeof useGetStripeAccountLinkQuery>;
export type GetStripeAccountLinkLazyQueryHookResult = ReturnType<typeof useGetStripeAccountLinkLazyQuery>;
export type GetStripeAccountLinkQueryResult = Apollo.QueryResult<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>;
export const GetOrganizationListByInputDocument = gql`
    query getOrganizationListByInput($searchOragnizationInput: String!) {
  getOrganizationListByInput(searchOragnizationInput: $searchOragnizationInput) {
    items {
      name
      email
      userId
      organizationId
      role
    }
  }
}
    `;

/**
 * __useGetOrganizationListByInputQuery__
 *
 * To run a query within a React component, call `useGetOrganizationListByInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationListByInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationListByInputQuery({
 *   variables: {
 *      searchOragnizationInput: // value for 'searchOragnizationInput'
 *   },
 * });
 */
export function useGetOrganizationListByInputQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationListByInputQuery, GetOrganizationListByInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationListByInputQuery, GetOrganizationListByInputQueryVariables>(GetOrganizationListByInputDocument, options);
      }
export function useGetOrganizationListByInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationListByInputQuery, GetOrganizationListByInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationListByInputQuery, GetOrganizationListByInputQueryVariables>(GetOrganizationListByInputDocument, options);
        }
export type GetOrganizationListByInputQueryHookResult = ReturnType<typeof useGetOrganizationListByInputQuery>;
export type GetOrganizationListByInputLazyQueryHookResult = ReturnType<typeof useGetOrganizationListByInputLazyQuery>;
export type GetOrganizationListByInputQueryResult = Apollo.QueryResult<GetOrganizationListByInputQuery, GetOrganizationListByInputQueryVariables>;
export const RetrieveOrganizationDocument = gql`
    query retrieveOrganization($id: String!) {
  retrieveOrganization(id: $id) {
    object {
      id
      name
      country
      logoUrl
      phoneNumber
      owner {
        id
        email
        phone
      }
    }
  }
}
    `;

/**
 * __useRetrieveOrganizationQuery__
 *
 * To run a query within a React component, call `useRetrieveOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetrieveOrganizationQuery(baseOptions: Apollo.QueryHookOptions<RetrieveOrganizationQuery, RetrieveOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveOrganizationQuery, RetrieveOrganizationQueryVariables>(RetrieveOrganizationDocument, options);
      }
export function useRetrieveOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveOrganizationQuery, RetrieveOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveOrganizationQuery, RetrieveOrganizationQueryVariables>(RetrieveOrganizationDocument, options);
        }
export type RetrieveOrganizationQueryHookResult = ReturnType<typeof useRetrieveOrganizationQuery>;
export type RetrieveOrganizationLazyQueryHookResult = ReturnType<typeof useRetrieveOrganizationLazyQuery>;
export type RetrieveOrganizationQueryResult = Apollo.QueryResult<RetrieveOrganizationQuery, RetrieveOrganizationQueryVariables>;
export const UpdateOrganizationHubspotPropertyDocument = gql`
    mutation updateOrganizationHubspotProperty($input: UpdateOrganizationHubspotPropertyInput!) {
  updateOrganizationHubspotProperty(input: $input) {
    object
  }
}
    `;
export type UpdateOrganizationHubspotPropertyMutationFn = Apollo.MutationFunction<UpdateOrganizationHubspotPropertyMutation, UpdateOrganizationHubspotPropertyMutationVariables>;

/**
 * __useUpdateOrganizationHubspotPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationHubspotPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationHubspotPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationHubspotPropertyMutation, { data, loading, error }] = useUpdateOrganizationHubspotPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationHubspotPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationHubspotPropertyMutation, UpdateOrganizationHubspotPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationHubspotPropertyMutation, UpdateOrganizationHubspotPropertyMutationVariables>(UpdateOrganizationHubspotPropertyDocument, options);
      }
export type UpdateOrganizationHubspotPropertyMutationHookResult = ReturnType<typeof useUpdateOrganizationHubspotPropertyMutation>;
export type UpdateOrganizationHubspotPropertyMutationResult = Apollo.MutationResult<UpdateOrganizationHubspotPropertyMutation>;
export type UpdateOrganizationHubspotPropertyMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationHubspotPropertyMutation, UpdateOrganizationHubspotPropertyMutationVariables>;
export const UpdateOrganizationCategoryDocument = gql`
    mutation updateOrganizationCategory($category: OrganizationCategory!) {
  updateOrganizationCategory(category: $category) {
    object
  }
}
    `;
export type UpdateOrganizationCategoryMutationFn = Apollo.MutationFunction<UpdateOrganizationCategoryMutation, UpdateOrganizationCategoryMutationVariables>;

/**
 * __useUpdateOrganizationCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationCategoryMutation, { data, loading, error }] = useUpdateOrganizationCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateOrganizationCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationCategoryMutation, UpdateOrganizationCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationCategoryMutation, UpdateOrganizationCategoryMutationVariables>(UpdateOrganizationCategoryDocument, options);
      }
export type UpdateOrganizationCategoryMutationHookResult = ReturnType<typeof useUpdateOrganizationCategoryMutation>;
export type UpdateOrganizationCategoryMutationResult = Apollo.MutationResult<UpdateOrganizationCategoryMutation>;
export type UpdateOrganizationCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationCategoryMutation, UpdateOrganizationCategoryMutationVariables>;
export const UpdateOrganizationOnboardedFormCategoryDocument = gql`
    mutation updateOrganizationOnboardedFormCategory($onboardedFormCategory: FormCategory!) {
  updateOrganizationOnboardedFormCategory(
    onboardedFormCategory: $onboardedFormCategory
  ) {
    object
  }
}
    `;
export type UpdateOrganizationOnboardedFormCategoryMutationFn = Apollo.MutationFunction<UpdateOrganizationOnboardedFormCategoryMutation, UpdateOrganizationOnboardedFormCategoryMutationVariables>;

/**
 * __useUpdateOrganizationOnboardedFormCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationOnboardedFormCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationOnboardedFormCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationOnboardedFormCategoryMutation, { data, loading, error }] = useUpdateOrganizationOnboardedFormCategoryMutation({
 *   variables: {
 *      onboardedFormCategory: // value for 'onboardedFormCategory'
 *   },
 * });
 */
export function useUpdateOrganizationOnboardedFormCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationOnboardedFormCategoryMutation, UpdateOrganizationOnboardedFormCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationOnboardedFormCategoryMutation, UpdateOrganizationOnboardedFormCategoryMutationVariables>(UpdateOrganizationOnboardedFormCategoryDocument, options);
      }
export type UpdateOrganizationOnboardedFormCategoryMutationHookResult = ReturnType<typeof useUpdateOrganizationOnboardedFormCategoryMutation>;
export type UpdateOrganizationOnboardedFormCategoryMutationResult = Apollo.MutationResult<UpdateOrganizationOnboardedFormCategoryMutation>;
export type UpdateOrganizationOnboardedFormCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationOnboardedFormCategoryMutation, UpdateOrganizationOnboardedFormCategoryMutationVariables>;
export const DismissActivationPayoutSuccessCardDocument = gql`
    mutation dismissActivationPayoutSuccessCard {
  dismissActivationPayoutSuccessCard {
    object
  }
}
    `;
export type DismissActivationPayoutSuccessCardMutationFn = Apollo.MutationFunction<DismissActivationPayoutSuccessCardMutation, DismissActivationPayoutSuccessCardMutationVariables>;

/**
 * __useDismissActivationPayoutSuccessCardMutation__
 *
 * To run a mutation, you first call `useDismissActivationPayoutSuccessCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissActivationPayoutSuccessCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissActivationPayoutSuccessCardMutation, { data, loading, error }] = useDismissActivationPayoutSuccessCardMutation({
 *   variables: {
 *   },
 * });
 */
export function useDismissActivationPayoutSuccessCardMutation(baseOptions?: Apollo.MutationHookOptions<DismissActivationPayoutSuccessCardMutation, DismissActivationPayoutSuccessCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissActivationPayoutSuccessCardMutation, DismissActivationPayoutSuccessCardMutationVariables>(DismissActivationPayoutSuccessCardDocument, options);
      }
export type DismissActivationPayoutSuccessCardMutationHookResult = ReturnType<typeof useDismissActivationPayoutSuccessCardMutation>;
export type DismissActivationPayoutSuccessCardMutationResult = Apollo.MutationResult<DismissActivationPayoutSuccessCardMutation>;
export type DismissActivationPayoutSuccessCardMutationOptions = Apollo.BaseMutationOptions<DismissActivationPayoutSuccessCardMutation, DismissActivationPayoutSuccessCardMutationVariables>;
export const AddEngagementStepCheckedDocument = gql`
    mutation addEngagementStepChecked($activationStepId: String!) {
  addEngagementStepChecked(activationStepId: $activationStepId) {
    object
  }
}
    `;
export type AddEngagementStepCheckedMutationFn = Apollo.MutationFunction<AddEngagementStepCheckedMutation, AddEngagementStepCheckedMutationVariables>;

/**
 * __useAddEngagementStepCheckedMutation__
 *
 * To run a mutation, you first call `useAddEngagementStepCheckedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEngagementStepCheckedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEngagementStepCheckedMutation, { data, loading, error }] = useAddEngagementStepCheckedMutation({
 *   variables: {
 *      activationStepId: // value for 'activationStepId'
 *   },
 * });
 */
export function useAddEngagementStepCheckedMutation(baseOptions?: Apollo.MutationHookOptions<AddEngagementStepCheckedMutation, AddEngagementStepCheckedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEngagementStepCheckedMutation, AddEngagementStepCheckedMutationVariables>(AddEngagementStepCheckedDocument, options);
      }
export type AddEngagementStepCheckedMutationHookResult = ReturnType<typeof useAddEngagementStepCheckedMutation>;
export type AddEngagementStepCheckedMutationResult = Apollo.MutationResult<AddEngagementStepCheckedMutation>;
export type AddEngagementStepCheckedMutationOptions = Apollo.BaseMutationOptions<AddEngagementStepCheckedMutation, AddEngagementStepCheckedMutationVariables>;
export const ResetEngagementStepCheckedDocument = gql`
    mutation resetEngagementStepChecked {
  resetEngagementStepChecked {
    object
  }
}
    `;
export type ResetEngagementStepCheckedMutationFn = Apollo.MutationFunction<ResetEngagementStepCheckedMutation, ResetEngagementStepCheckedMutationVariables>;

/**
 * __useResetEngagementStepCheckedMutation__
 *
 * To run a mutation, you first call `useResetEngagementStepCheckedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEngagementStepCheckedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEngagementStepCheckedMutation, { data, loading, error }] = useResetEngagementStepCheckedMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetEngagementStepCheckedMutation(baseOptions?: Apollo.MutationHookOptions<ResetEngagementStepCheckedMutation, ResetEngagementStepCheckedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetEngagementStepCheckedMutation, ResetEngagementStepCheckedMutationVariables>(ResetEngagementStepCheckedDocument, options);
      }
export type ResetEngagementStepCheckedMutationHookResult = ReturnType<typeof useResetEngagementStepCheckedMutation>;
export type ResetEngagementStepCheckedMutationResult = Apollo.MutationResult<ResetEngagementStepCheckedMutation>;
export type ResetEngagementStepCheckedMutationOptions = Apollo.BaseMutationOptions<ResetEngagementStepCheckedMutation, ResetEngagementStepCheckedMutationVariables>;
export const DisplayEngagementChecklistDocument = gql`
    mutation displayEngagementChecklist {
  displayEngagementChecklist {
    object
  }
}
    `;
export type DisplayEngagementChecklistMutationFn = Apollo.MutationFunction<DisplayEngagementChecklistMutation, DisplayEngagementChecklistMutationVariables>;

/**
 * __useDisplayEngagementChecklistMutation__
 *
 * To run a mutation, you first call `useDisplayEngagementChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisplayEngagementChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [displayEngagementChecklistMutation, { data, loading, error }] = useDisplayEngagementChecklistMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisplayEngagementChecklistMutation(baseOptions?: Apollo.MutationHookOptions<DisplayEngagementChecklistMutation, DisplayEngagementChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisplayEngagementChecklistMutation, DisplayEngagementChecklistMutationVariables>(DisplayEngagementChecklistDocument, options);
      }
export type DisplayEngagementChecklistMutationHookResult = ReturnType<typeof useDisplayEngagementChecklistMutation>;
export type DisplayEngagementChecklistMutationResult = Apollo.MutationResult<DisplayEngagementChecklistMutation>;
export type DisplayEngagementChecklistMutationOptions = Apollo.BaseMutationOptions<DisplayEngagementChecklistMutation, DisplayEngagementChecklistMutationVariables>;
export const RemoveEngagementChecklistDocument = gql`
    mutation removeEngagementChecklist {
  removeEngagementChecklist {
    object
  }
}
    `;
export type RemoveEngagementChecklistMutationFn = Apollo.MutationFunction<RemoveEngagementChecklistMutation, RemoveEngagementChecklistMutationVariables>;

/**
 * __useRemoveEngagementChecklistMutation__
 *
 * To run a mutation, you first call `useRemoveEngagementChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEngagementChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEngagementChecklistMutation, { data, loading, error }] = useRemoveEngagementChecklistMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveEngagementChecklistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEngagementChecklistMutation, RemoveEngagementChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEngagementChecklistMutation, RemoveEngagementChecklistMutationVariables>(RemoveEngagementChecklistDocument, options);
      }
export type RemoveEngagementChecklistMutationHookResult = ReturnType<typeof useRemoveEngagementChecklistMutation>;
export type RemoveEngagementChecklistMutationResult = Apollo.MutationResult<RemoveEngagementChecklistMutation>;
export type RemoveEngagementChecklistMutationOptions = Apollo.BaseMutationOptions<RemoveEngagementChecklistMutation, RemoveEngagementChecklistMutationVariables>;
export const UpdateOrganizationFlagsDocument = gql`
    mutation updateOrganizationFlags($input: OrganizationFlagsInput!) {
  updateOrganizationFlags(input: $input) {
    object
    error {
      code
      message
    }
  }
}
    `;
export type UpdateOrganizationFlagsMutationFn = Apollo.MutationFunction<UpdateOrganizationFlagsMutation, UpdateOrganizationFlagsMutationVariables>;

/**
 * __useUpdateOrganizationFlagsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationFlagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationFlagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationFlagsMutation, { data, loading, error }] = useUpdateOrganizationFlagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationFlagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationFlagsMutation, UpdateOrganizationFlagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationFlagsMutation, UpdateOrganizationFlagsMutationVariables>(UpdateOrganizationFlagsDocument, options);
      }
export type UpdateOrganizationFlagsMutationHookResult = ReturnType<typeof useUpdateOrganizationFlagsMutation>;
export type UpdateOrganizationFlagsMutationResult = Apollo.MutationResult<UpdateOrganizationFlagsMutation>;
export type UpdateOrganizationFlagsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationFlagsMutation, UpdateOrganizationFlagsMutationVariables>;